import { Box, Tab, TabProps, Typography } from '@mui/material'
import { formatBalanceWithDecimals } from 'utils/intlFormat'
import { Currency } from 'types/currency'
import { WalletType } from 'types/wallet'

import s from './../styles'


interface Props extends TabProps {
  walletName: string
  currency: Currency | undefined
  selected?: boolean
  type?: WalletType
  margin?: string
  balance?: string,
  reserved?: string
}
const CustomTab = ({ walletName, currency, margin, type, balance, reserved, ...props }: Props) => {

  return (
    <Box sx={s.getWrapperStyles(!!props?.selected)} m={margin}>
      {/* <SvgIcon
        component={getCurrencyIcon[currency]}
        sx={s.getIconStyles(!!props?.selected)}
        inheritViewBox
      /> */}
      <Tab
        {...props}
        sx={s.tab}
        tabIndex={0}
        label= {
          <Box sx={s.label(!!props?.selected)}>
            <Typography sx={s.balance}>{formatBalanceWithDecimals(balance, currency?.maxDecimals)} {currency?.symbol}</Typography>
            <Typography sx={s.reserved}>Reserved: {formatBalanceWithDecimals(reserved, currency?.maxDecimals)} {currency?.symbol}</Typography>
            <Typography sx={s.type}>{type}</Typography>
          </Box>
        }
      />
      <Typography sx={s.name}>{walletName}</Typography>
    </Box>
  )
}

export default CustomTab
