import { yupResolver } from '@hookform/resolvers/yup'
import { Button, Paper } from '@mui/material'
import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import Typography from '@mui/material/Typography'
import { useForm } from 'react-hook-form'
import { Navigate, useSearchParams } from 'react-router-dom'

import BackButton from 'components/BackButton'
import Preloader from 'components/Preloader'
import StripeDialog from 'components/StripeDialog'
import TextInput from 'components/TextInput'

import { AppRoute, portalUiUrl } from 'assets/const'
import { useMediaSize } from 'hooks/useMediaSize'
import { useRefillMutation } from 'services/operations'
import { useFetchUserProfileQuery } from 'services/user'
import { useGetWalletQuery } from 'services/wallets'
import { refillSchema } from './validation'


interface FormValues {
  amount: string
  description: string
}

const Refill = () => {
  const { isPhone } = useMediaSize()

  const [searchParams] = useSearchParams()
  const walletId = searchParams.get('wallet')
  const currency = searchParams.get('currency')

  const { data: user, isLoading: isLoadingUser } = useFetchUserProfileQuery()
  const [refill, { isLoading }] = useRefillMutation()
  const { data: wallet, isLoading: isWalletLoading } = useGetWalletQuery(
    walletId ?? '',
    {
      skip: !walletId,
    }
  )

  const {
    handleSubmit,
    control,
    formState: { errors, isValid },
  } = useForm<FormValues>({
    defaultValues: {
      amount: '',
      description: '',
    },
    mode: 'onBlur',
    resolver: yupResolver(refillSchema),
    context: { maxDecimals: 5, minValidAmount: 0 }
  })

  const onSubmit = (formData: FormValues) => {
    if (!walletId || !currency || !user) return

     refill({
      amount: formData.amount,
      currency,
      operationType: 'refill',
      walletId,
      metadata: {
        description: formData.description,
      },
      redirectUrl: window.location.origin,
    })
      .unwrap()
      .then((res) => {
        if (res.token) {
          window.location.href = `${portalUiUrl}?token=${res.token}?returnUrl=${window.location.href}`
        }
      })
  }

  if (isWalletLoading || isLoadingUser) {
    return <Preloader />
  }

  if (!wallet) {
    return <Navigate to={`/${AppRoute.NotFound}`} />
  }

  return (
    <Container maxWidth={isPhone ? 'sm' : 'lg'} disableGutters sx={{display: 'flex', flexDirection: 'column'}}>
      <BackButton />
      <Paper
        component='form'
        noValidate
        onSubmit={handleSubmit(onSubmit)}
        sx={{
          display: 'flex',
          width: {xs: 'auto', md: '466px'},
          flexDirection: 'column',
          justifyContent: 'space-between',
          alignSelf: {xs: 'center', md: 'start'},
          p: {xs: 2, sm: 3},
          ml: {sm: 0, md: 9}
        }}
      >
        <Typography variant={isPhone ? 'h3' : 'h2'}>
          Top up funds
        </Typography>
        <Typography variant={isPhone ? 'h4' : 'h3'} mt={2}>
          Please provide refill details
        </Typography>
        <Box>
          <TextInput
            name='amount'
            label='Amount'
            required
            fullWidth
            control={control}
            errors={errors}
            disabled={isLoading}
            sx={{mt: {xs: 2, sm: 3}}}
            FormHelperTextProps={{
              sx: {
                '&.MuiFormHelperText-root': {
                  height: { xs: 'auto', sm: 0 },
                  mb: { xs: '-10px', sm: 'auto' },
                },
              },
            }}
          />
          <TextInput
            name='description'
            label='Description'
            fullWidth
            control={control}
            errors={errors}
            disabled={isLoading}
            sx={{mt: {xs: 2, sm: 3}}}
            FormHelperTextProps={{
              sx: {
                '&.MuiFormHelperText-root': {
                  height: { xs: 'auto', sm: 0 },
                  mb: { xs: '-10px', sm: 'auto' },
                },
              },
            }}
          />
        </Box>

        <Button
          variant='contained'
          type='submit'
          disabled={isLoading || !isValid}
          fullWidth
          sx={{mt: {xs: 3, sm: 4}}}
        >
          Submit
        </Button>
      </Paper>
      <StripeDialog
        walletType={wallet.type}
        connectedAccountId={
          user?.businessSettings?.paymentSettings?.stripe?.connectedAccountId
        }
        isOperationAllowed={
          user?.businessSettings?.paymentSettings?.stripe?.canPayment &&
          user?.businessSettings?.paymentSettings?.stripe?.canPayout
        }
      />
    </Container>
  )
}

export default Refill