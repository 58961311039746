import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

import AvatarUpload from 'components/AvatarUpload'
import Preloader from 'components/Preloader'
import ProfileLayout from '../profile/ProfileLayout'

import { useFetchUserProfileQuery } from 'services/user'
import { useMediaSize } from 'hooks/useMediaSize'

import s from './styles'


const UserInfo = () => {
  const { data: user, isLoading: isUserLoading } = useFetchUserProfileQuery()
  const {isPhone} = useMediaSize()

  if ( isUserLoading ) return <Preloader withLeftMargin/>

  return (
    <ProfileLayout title='Information'>
      <Box sx={s.mainBox}>
        <AvatarUpload />

            {/* <Box className={s.label}>Verification status:</Box>
          {AuthService.isEmailVerified() ? (
            <Box className={s.status}>Verified</Box>
          ) : (
            <Box className={`${s.status} ${s.notVerified}`}>Not verified</Box>
          )} */}

        <Box sx={s.firstBox}>
          <Box mb={isPhone ? 3 : 4} width='100%'>
            <Typography sx={s.label}>First Name:</Typography>
            <Typography sx={s.value}>{user?.firstName}</Typography>
          </Box>
          <Box >
            <Typography sx={s.label}>Email:</Typography>
            <Typography sx={s.value}>{user?.email}</Typography>
          </Box>
        </Box>
        <Box sx={s.secondBox}>
          <Box mb={isPhone ? 3 : 4}>
            <Typography sx={s.label}>Last Name:</Typography>
            <Typography sx={s.value}>{user?.lastName}</Typography>
          </Box>
          <Box>
            <Typography sx={s.label}>Username:</Typography>
            <Typography sx={s.value}>{user?.username}</Typography>
          </Box>
        </Box>

      </Box>
    </ProfileLayout>
  )
}

export default UserInfo
