import { Box, Typography } from '@mui/material'
import { useEffect, useState } from 'react'

import CardComponent from 'components/PaymentCard'
import EditDetails from '../EditDetails'
import ScrollableBox from 'components/ScrollableBox'

import { getPaymentIcon } from 'assets/const'
import { useCreateTemplateMutation } from 'services/templates'
import { PSDefaultTemplate, PSTemplateRequest } from 'types/template'


interface Props {
  defaultTemplates: PSDefaultTemplate[]
  isSavedUpdating: boolean
  setIsSavedUpdating: (isSavedUpdating: boolean) => void
  isModalOpen: boolean
  setIsModalOpen: (isModalOpen: boolean) => void
}

const NewTemplate = ({
  defaultTemplates,
  isSavedUpdating,
  isModalOpen,
  setIsSavedUpdating,
  setIsModalOpen,
}: Props) => {
  const [activeTemplate, setActiveTemplate] = useState<
    PSDefaultTemplate | PSTemplateRequest | null
  >(null)
  const [createTemplate, { isLoading: isFetching }] =
    useCreateTemplateMutation()

  const handleModalClose = () => {
    setIsModalOpen(false)
  }

  const handleCreateTemplate = (template: PSTemplateRequest) => {
    createTemplate(template)
    setIsSavedUpdating(true)
  }

  useEffect(() => {
    if (isModalOpen) return
    const getClick = () => {
      setActiveTemplate(null)
    }
    document.addEventListener('click', getClick)
    return () => {
      document.removeEventListener('click', getClick)
    }
  }, [isModalOpen])

  return (
    <>
      <Box >
        <Typography sx={{ pt: 4, pb: 3, fontWeight: 500 }}>
          Create new template:
        </Typography>
        <ScrollableBox
          sx={{
            display: 'flex',
            gap: '14px',
            pb: '12px',
          }}
        >
          {defaultTemplates?.map((template, index) => (
            <CardComponent
              key={template.psCode + index}
              icon={getPaymentIcon[template.psCode]}
              template={template}
              activeTemplate={activeTemplate}
              setActive={setActiveTemplate}
              setIsModalOpen={setIsModalOpen}
            />
          ))}
        </ScrollableBox>
        {/* <Grid
          container
          spacing={3}
          sx={{ justifyContent: isMobileDevice ? 'center' : 'flex-start' }}
        >
          <Grid
            item
            xs={12}
            sm={6}
            md={6}
            lg={6}
            sx={{
              display: 'flex',
              justifyContent: isMobileDevice ? 'center' : 'flex-start',
            }}
          >
            <Button
              variant='contained'
              fullWidth={isMobileDevice}
              disabled={!activeTemplate}
              onClick={(evt) => {
                evt.stopPropagation()
                setIsModalOpen(true)
              }}
            >
              Create
            </Button>
          </Grid>
        </Grid> */}
      </Box>
      {isModalOpen && activeTemplate && (
        <EditDetails
          template={activeTemplate as PSDefaultTemplate}
          isFetching={isSavedUpdating || isFetching}
          handleSave={handleCreateTemplate}
          handleClose={handleModalClose}
        />
      )}
    </>
  )
}

export default NewTemplate
