import { MutableRefObject } from 'react'
import { SxProps, Theme } from '@mui/material'
import { theme } from 'theme'

const headerWrapper: SxProps = {
  height: '75px',
  display: 'flex',
  justifyContent: 'space-between',
  background: 'rgba(255, 255, 255, 0.94)',
  backdropFilter: 'blur(7px)',
  boxShadow: '0px 0px 10px 1px #00000020',
  color: 'primary',
  position: 'fixed',
  top: 0,
  right: 0,
  zIndex: 1000,
  width: '100%',
  px: {xs: 2, sm: 2.5, md: 3}
}

const logoWrapper: SxProps = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: {xs: '40px', md: '208px'},
  mr: {xs: '0', sm: '0', md: '24px'},
  ml: {xs: '16px', sm: '0'},
  '& a': {display: 'flex'},
  flexShrink: 0,
}

const headerContent = (position: string): SxProps<Theme> => ({
  display: 'flex',
  flex: 1,
  justifyContent: {xs: position, sm: 'space-between'},
  alignItems: 'center',
  height: '75px',
  pr: '24px',
  pl: {sm: '24px', md: '40px'}
})

const navMenuPaper: SxProps = {
  mt: '1px',
  ml: '16px',
  minWidth: '100%',
  boxShadow: 'none',
  borderRadius: '0 0 8px 8px'
}

const link = (active: boolean, disabled: boolean): SxProps<Theme> => ({
  fontWeight: active ? 600 : 400,
  fontSize: '14px',
  cursor: 'pointer',
  color: theme =>
    disabled ? theme.palette.gray.main :
    active
      ? theme.palette.primary.main
      : theme.palette.gray.dark,

  '&:hover': {
    color: disabled ? theme.palette.gray.main : theme.palette.primary.main
  },
})

const burgerWrapper: SxProps = {
  display: 'flex',
  alignItems: 'center',
}

const mobileLink = (active: boolean, disabled: boolean): SxProps<Theme> => ({
  minHeight: '42px',
  p: 0,
  width: '100%',
  flexGrow: 1,
  fontSize: '14px',
  height: '100%',
  backgroundColor: 'transparent',
  fontWeight: active ? 600 : 400,
  color: theme =>
    disabled ? theme.palette.gray.main :
    active
      ? theme.palette.primary.main
      : theme.palette.gray.dark,
  'a': {
    p: 1
  }
})

const navMenu: SxProps = {
  display: 'flex',
  gap: '50px',
  flexShrink: '0',
  flexGrow: '1',
}

const menuList: SxProps = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: { xs: 'stretch', sm: 'center' },
  justifyContent: 'center',
  height: { sm: '96px' },
  p: { xs: '10px', sm: '0' },
  '& a': {flex: 1, height: '100%'},
}

const userMenuWrapper: SxProps = {
  display: 'flex',
  ml: { xs: 'auto', md: 0 },
  cursor: 'pointer',
  textAlign: 'left',
  padding: 0,
  border: 'none',
  font: 'inherit',
  color: 'inherit',
  backgroundColor: 'transparent',
  alignSelf: 'center'
}

const userMenuItem: SxProps<Theme> = {
  fontSize: '14px',
  width: '100%',
  '& a': {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'left',
    // alignItems: 'center',
    fontSize: '14px',
  },
  '& div': {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'left',
    alignItems: 'center',
    fontSize: '14px',
  },
  '&.MuiButtonBase-root': {
    p: 0,
    minHeight: '42px'
  },
  '& svg': {
    mx: 1,
  },
}

const getUserMenuPaper = (
  ref: MutableRefObject<HTMLDivElement | null>
): SxProps => ({
  minWidth: {xs: '100%', sm: ref.current?.clientWidth},
  boxShadow: {xs: 'none', sm: '0px 0px 10px 1px #00000020'},
  ml: {xs: '16px', sm: 0},
  mt: {xs: '23px', sm: '18px'},
  borderRadius: '0 0 8px 8px '
})

const userMenuList: SxProps = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  justifyContent: 'center',
}

const avatar: SxProps = {
  width: '30px' ,
  height: '30px',
  alignSelf: 'center',
}

const addAccountBtn: SxProps = {
  minHeight: '43px',
  padding: '12px',
  textTransform: 'none',
  marginLeft: '35px',
  marginRight: '5px',
  flexShrink: '0',
}

const textName: SxProps = {
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  color: theme.palette.gray.main,
  fontWeight: 600,
  fontSize: '16px'
}

const textMail: SxProps = {
  fontSize: '12px',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  color: 'gray.dark'
}

const Styles = {
  headerWrapper,
  logoWrapper,
  headerContent,
  menuList,
  navMenuPaper,
  navMenu,
  link,
  burgerWrapper,
  mobileLink,
  userMenuItem,
  getUserMenuPaper,
  userMenuWrapper,
  avatar,
  userMenuList,
  addAccountBtn,
  textName,
  textMail,
}

export default Styles
