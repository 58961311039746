import { SxProps } from '@mui/material'
import { theme } from 'theme'

const wrapper: SxProps = {
  bgcolor: '#FFFFFF',
  display: 'flex',
  flex: 1,
  flexFlow: 0,
  flexDirection: {xs: 'column', lg: 'row'},
  justifyContent: 'space-between',
  p: { md: '24px' },
  width: '100%',
  // minHeight: { md: '172px', sm: '192px', xs: '160px' },

  '& .swiper-pagination': {
    position: 'relative',
    bottom: { xs: '30px', sm: '20px' },
  },

  '& .swiper-pagination-bullet': {
    m: { sm: '0 6px !important' },
    width: { xs: '10px', sm: '16px' },
    height: { xs: '10px', sm: '16px' },
    background: 'inherit',
    opacity: 1,
    border: '1px solid #eef2f7',
    minWidth: 0,
  },

  '& .swiper-pagination-bullet-active': {
    background: '#fff',
  },
}
const addWalletBtn: SxProps = {
  fontSize: '14px',
  textTransform: 'none',
  left: '0',
  m: { xs: '15px auto', sm: '0 auto', md: 'auto 0 0 116px' },
}

const avatar: SxProps = {
  width: { xs: '100px', sm: '140px', md: '150px' },
  height: { xs: '100px', sm: '140px', md: '150px' },
  boxSizing: 'border-box',
  bgcolor: 'transparent',
  border: '1px solid',
  borderColor: 'primary.main',
  color: 'primary.main',
  fontSize: { xs: '70px', sm: '90px', md: '100px' },
  letterSpacing: '-2px'
}

const infoContainer: SxProps = {
  zIndex: 1,
  display: 'flex',
  alignItems: 'center',
  gap: { xs: '15px', sm: '24px' },
  whiteSpace: 'break-spaces',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
}

const balanceContainer: SxProps = {
  overflow: 'hidden',
  textOverflow: 'ellipsis',
}

const walletName: SxProps = {
  fontSize: {xs: '12px', sm: '16px'},
  fontWeight: 600,
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  textTransform: 'uppercase',
  letterSpacing: 0,
}

const mainBalance: SxProps = {
  fontSize: { xs: '20px', sm: '32px' },
  fontWeight: 600,
  whiteSpace: 'nowrap',
  lineHeight: 1.3
}

const reservedBalance: SxProps = {
  fontSize: { xs: '12px', sm: '16px' },
  fontWeight: 500,
}

const reservedText: SxProps = {
  ...reservedBalance,
  display: 'inline-block',
  fontWeight: {xs: 400, sm: 500},
  pr: 3,
  'span': {
    whiteSpace: 'nowrap',
    fontWeight: {xs: 400, sm: 600},
  }
}

const walletType: SxProps = {
  ...reservedBalance,
  fontWeight: 600,
  opacity: .5,
}

const operationContainer: SxProps = {
  zIndex: 1,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  alignItems: 'end',
}

const settingIcon: SxProps = {
  cursor: 'pointer',
  color: theme.palette.gray.main,
  transition: 'color .2s',
  ':hover': {
    color: 'gray.dark',
    transition: 'color .2s'
  }
}

const settingsMenu: SxProps = {
  ml: 1,
  mt: '12px',
  'ul': {
    fontSize: '14px',
    'li': {
      gap: '8px',
      p: '11px 16px',
      fontSize: '14px',
      letterSpacing: '-1px',
      // ':hover': {bgcolor: 'primary.main'},
    }
  }
}


const Styles = {
  wrapper,
  addWalletBtn,
  avatar,
  infoContainer,
  walletName,
  mainBalance,
  reservedBalance,
  reservedText,
  walletType,
  balanceContainer,
  operationContainer,
  settingIcon,
  settingsMenu
}

export default Styles
